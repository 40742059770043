import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Import Slider component
import "slick-carousel/slick/slick.css"; // Import slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles
import BANNER1 from 'assets/Logistics/logistics_banner1.png';
import BANNER2 from 'assets/Logistics/logistics_banner2.png';
import YELLOW_LOGO from 'assets/Logistics/yellow_logistics_logo.svg';
import SHG from "assets/Logistics/SHG.svg";
import PACKINGTON from "assets/Logistics/packington.svg";
import FIC from "assets/Logistics/FIC.svg";
import IG_ICON from "assets/Logistics/instagram.svg";
import CS from "assets/Logistics/cs.png";
import boxLists from './boxLists';
import "./style.scss";
import BoxDetail from './BoxDetail';

const MobileLogisticsLanding = React.memo((props) => {

  const settings = {
    dots: true,          // Show navigation dots below the slider
    infinite: true,      // Enable infinite looping
    speed: 500,          // Transition speed in milliseconds
    slidesToShow: 1,     // Show one slide at a time
    slidesToScroll: 1    // Scroll one slide at a time
  };

  return (
    <div className="MobileLogisticsLanding">
      {/* <Slider {...settings}> */}
        {/* Slide 1: BANNER1 with yellow logo */}
        <div className="banner-container">
          <img className="banner1" src={BANNER1} alt="" />
          <img className="yellow-logo" src={YELLOW_LOGO} alt="" />
        </div>
        {/* Slide 2: BANNER2 with yellow logo */}
        {/* <div className="banner-container">
          <img className="banner2" src={BANNER2} alt="" />
          <img className="yellow-logo" src={YELLOW_LOGO} alt="" />
        </div>
      </Slider> */}
      <div className="paragraph p1">
        <div className="top-title">Door to Door</div>
        <div className="main-title">Storage</div>
        <div className="detail">
          Squeeze out those valuable square feets by storing your belongings with us Feaston Industrial Centre where an wall of opium carton stands. Our in-house logistics team will transport packed cartons back to our site at Kowloon Bay and look after them personally. Need to recall something?
          <br/>Book an appointment to have it at your doorstep
        </div>
      </div>
      <div className="book-now">
        <a aria-label="Chat on WhatsApp" href="https://wa.me/60283591" target="_blank" rel="noopener noreferrer">
          <div className="button">
            BOOK NOW
          </div>
        </a>
      </div>
      <div className="banner-container b2-container">
        <img className="banner2" src={BANNER2} alt="" />
      </div>
      <div className="paragraph p2">
        <div className="top-title">Door to Door</div>
        <div className="main-title">Storage</div>
        <div className="detail">
          Our Logistics team will pay a visit to your door and store your belongings at the Feaston Industrial Centre.
          <br/><br/>Boxes will be delivered back to you upon request.
          <br/><br/>Store with us for as low as
          <div className="price">$55/box/month</div>
        </div>
      </div>
      <div className="book-now">
        <a aria-label="Chat on WhatsApp" href="https://wa.me/60283591" target="_blank" rel="noopener noreferrer">
          <div className="button">
            BOOK NOW
          </div>
        </a>
      </div>
      <div className="boxes-wrapper">
        {boxLists.map((box) => <BoxDetail box={box} key={box.size} />)}
      </div>
      <div className="boxes-points">
        <ul>
          <li>3 months minimum storage duration</li>
          <li>10% discount off payments made per annum</li>
          <li>Registered Clients enjoy discounts off Feaston Merchandise</li>
        </ul>
      </div>
      <div className="footer">
        <div className="powered-by">Powered by</div>
        <div className="powered-by-lists">
          <div className="powered-by-lists-row">
            <img className="shg" src={SHG} alt="" />
            <img className="packington" src={PACKINGTON} alt="" />
          </div>
          <div className="powered-by-lists-row">
            <img className="fic" src={FIC} alt="" />
          </div>
          {/* <div className="about-us">About Us</div>
          <div className="tnc">Terms & Conditions</div> */}
          <div className="contact-wrapper">
            <div className="left">
              <div className="cs-hotline">
                <div className="hotline">Customer Service Hotline</div>
                <a href="tel:60283591" className="number">60283591</a>
              </div>
            </div>
            <div className="right">
              <div className="contact-icon">
                <a href="https://www.instagram.com/feaston96" target="_blank" rel="noopener noreferrer">
                  <img className="ig-icon" src={IG_ICON} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a aria-label="Chat on WhatsApp" href="https://wa.me/60283591" target="_blank" rel="noopener noreferrer">
        <div className="floating">
          <img src={CS} alt="" />
        </div>
      </a>
    </div>
  );
})

export default MobileLogisticsLanding;