import BannerSlider from './BannerSlider';
import MainContent from './MainContent';
import Footer from './Footer';
import WhatsAppButton from './WhatsAppButton';
import './style.scss';

const LogisticsLanding = () => (
  <div className="LogisticsLanding">
    <BannerSlider />
    <MainContent />
    <Footer />
    <WhatsAppButton />
  </div>
);

export default LogisticsLanding;