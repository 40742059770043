import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseId = 'appi9EVpnplOUbCEi';
const customerTblId = 'tblCV0ZBwdlQfhpXw';
const boxTblId = 'tblGYxAUuyHIUcVfG';

const airtableApi = createApi({
  reducerPath: 'airtableApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.airtable.com/',
    prepareHeaders: (headers) => {
      headers.set('Authorization', 'Bearer patVG9zN6wVOwILRj.41efb7bd508164b277bcda19ecc79f7ecd67d7444cf05ad2e775b62e2f152c02');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    listCustomer: builder.query({
      query: (phone) => `/v0/${baseId}/${customerTblId}?filterByFormula=%7BPhoneNumber%7D%3D${phone}`,
      transformResponse: (response) => response.records[0],
    }),
    getCustomer: builder.query({
      query: (id) => `/v0/${baseId}/${customerTblId}/${id}`,
      transformResponse: (response) => response.records[0],
    }),
    listBox: builder.query({
      query: (id) => `/v0/${baseId}/${boxTblId}?filterByFormula=%7BStorageUnitID%7D%3D${id}`,
      transformResponse: (response) => response.records[0],
    }),
    getBox: builder.query({
      query: (id) => `?filterByFormula=ID="${id}"`,
      transformResponse: (response) => response.records[0],
    }),
    updateBoxStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/v0/${baseId}/${boxTblId}/${id}`,
        method: 'PATCH',
        body: {
          fields: {
            "Status": status,
          },
        },
      }),
    }),
    updateData: builder.mutation({
      query: ({ id, wallet_address, email, mobile }) => ({
        url: id,
        method: 'PATCH',
        body: {
          fields: {
            "Redeem Status": "Requested",
            "WalletID": wallet_address,
            ...(email && { "Email": email }),
            ...(mobile && { "Phone Number": mobile }),
          },
        },
      }),
    }),
  }),
});

export const {
  useLazyListCustomerQuery,
  useListCustomerQuery,
  useGetCustomerQuery,
  useLazyListBoxQuery,
  useListBoxQuery,
  useGetBoxQuery,
  useUpdateBoxStatusMutation,
  useUpdateDataMutation
} = airtableApi;
export default airtableApi;