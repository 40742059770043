import womanImage from 'assets/Logistics/cs.png'; // Replace with your image path
import './style.scss';

const WhatsAppButton = () => (
  <a
    href="https://wa.me/60283591"
    target="_blank"
    rel="noopener noreferrer"
    className="whatsapp-button"
  >
    <img src={womanImage} alt="Contact us on WhatsApp" />
  </a>
);

export default WhatsAppButton;