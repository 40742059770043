import IG_ICON from "assets/Logistics/instagram.svg";
import SHG from "assets/Logistics/SHG.svg";
import PACKINGTON from "assets/Logistics/packington.svg";
import FIC from "assets/Logistics/FIC.svg";
import "./style.scss";

const Footer = () => (
  <footer className="LogisticsLandingFooter">
    <div>
      <div className="powered-by">Powered by</div>
      <div className="powered-by-lists">
        <div className="powered-by-lists-row">
          <img className="shg" src={SHG} alt="" />
          <img className="packington" src={PACKINGTON} alt="" />
        </div>
      </div>
    </div>
    <div className="contact">
      <div className="left">
        <img className="fic" src={FIC} alt="" />
      </div>
      <div className="right">
        <div className="hotline">
          <div className="hotline-label">Customer Service Hotline</div>
          <a href="tel:60283591" className="hotline-number">60283591</a>
        </div>
        <a href="https://www.instagram.com/feaston96" target="_blank" rel="noopener noreferrer">
          <img className="ig-icon" src={IG_ICON} alt="Instagram Icon" />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;