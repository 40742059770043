import boxImage from '../assets/box-with-items.png';
import box from '../assets/logistics_box.png';
import './style.scss';
import boxLists from '_Mobile/scenes/MobileLogisticsLanding/boxLists';

const PricingCard = ({ size, price, freq, detail1, detail2 }) => (
  <div className="pricing-card">
    <div className="image">
      <img className="box" src={box} alt="" />
    </div>
    <div className="pricing-content">
      <div className="size">{size}</div>
      <div className="price">{price}</div>
      <div className="freq">{freq}</div>
      <div className="detail1">{detail1}</div>
      {detail2 && <div className="detail2">{detail2}</div>}
    </div>
  </div>
);

const MainContent = () => {
  return (
    <div className="main-content">
      <div className="promotional-section">
        <div className="text">
          <div className="top-title">Door to Door</div>
          <div className="main-title">Storage</div>
          <p>
            Our Logistics team will pay a visit to 
            your door and store your belongings 
            at the Feaston Industrial Centre.
            <br/><br/>
            Boxes will be delivered 
            back to you upon request.
            <br/><br/>
            Store with us for as low as
          </p>
          <p className="price">$55/box/month</p>
          <a aria-label="Chat on WhatsApp" href="https://wa.me/60283591" target="_blank" rel="noopener noreferrer">
            <button>BOOK NOW</button>
          </a>
        </div>
        <img src={boxImage} alt="Cardboard box with items" />
      </div>
      <div className="pricing-section">
        {boxLists.map((data, index) => (
          <PricingCard key={index} {...data} />
        ))}
        <div className="notes">
          <ul>
            <li>3 months minimum storage duration</li>
            <li>10% discount off payments made per annum</li>
            <li>Registered Clients enjoy discounts off Feaston Merchandise</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainContent;