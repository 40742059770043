import React, { Component, Fragment } from "react";
import "./App.css";
import Home from "./scenes/Home";
import Project from "./scenes/Project";
import MobileHome from "./_Mobile/scenes/MobileHome";
import MobileProduct from "./_Mobile/scenes/MobileProduct";
import MobileTerms from "./_Mobile/scenes/MobileTerms";
import MobileAbout from "./_Mobile/scenes/MobileAbout";
import MobileStockist from "./_Mobile/scenes/MobileStockist";
import MobileContact from "./_Mobile/scenes/MobileContact";
import MobileAddress from "./_Mobile/scenes/MobileAddress";
import MobileUpcoming from "./_Mobile/scenes/MobileUpcoming";
import MobilePast from "./_Mobile/scenes/MobilePast";
import MobileHome2021winter from "./_Mobile/scenes/MobileHome2021winter";
import MobileHome2022June from "./_Mobile/scenes/MobileHome2022June";
import MobileYearBookPage from "./_Mobile/scenes/MobileYearBookPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { withRouter } from "withRouter";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import MobileCart from "./_Mobile/scenes/MobileCart";
import { client } from "./shopify";
import { connect } from "react-redux";
import { fetchProducts } from "./actions/fetchProducts";
import { fetchBoosterPack } from "./actions/fetchBoosterPack";
import { fetchFeatureCollection } from "./actions/fetchFeatureCollection";
import { fetchArchivedFeatureCollections } from "./actions/fetchArchivedFeatureCollections";
// import './shared/app.css';
import { withCookies } from "react-cookie";
import MobileNavigation from "./_Mobile/components/MobileNavigation";
import Navigation from "./components/Navigation";
import { fetchCheckout } from "./actions/fetchCheckout";
import categories from "./data/categories";
import Product from "./scenes/Product";
import About from "./scenes/About";
import Cart from "./scenes/Cart";
import Contact from "./scenes/Contact";
import Address from "./scenes/Address";
import Terms from "./scenes/Terms";
import CreateReceipt from "./scenes/CreateReceipt";
import YearBook from "./scenes/YearBook";
import Home2021winter from "./scenes/Home2021winter";
import Home2022June from "./scenes/Home2022June";
import YearBookPage from "./scenes/YearBookPage";
import Test from "./scenes/Test";
import Test2 from "./scenes/Test2";
import Category from "scenes/Category";
import Stockist from "scenes/Stockist";
import Upcoming from "scenes/Upcoming";
import Past from "scenes/Past";
import MobileCategory from "_Mobile/scenes/MobileCategory";
import MobileGreatReset from "_Mobile/scenes/MobileGreatReset";
import GreatReset from "scenes/GreatReset";
import MobileProjectGeneral from "_Mobile/scenes/MobileProject/mobileProjectGeneral";
import ProjectGeneral from "scenes/Project/general";
import IframeFeastonBoosterPack from "scenes/IframeFeastonBoosterPack";
import TcgIntro from "./scenes/TcgIntro";
import PrintLabel from "./scenes/PrintLabel";
import PickupBox from "./scenes/PickupBox";
import UseScrollDirection from 'useScrollDirection';
import ArchivedFeatureCollections from "components/ArchivedFeatureCollections";
import ArchivedFeatureProduct from "components/ArchivedFeatureProduct";
import MobileArchivedFeatureCollections from "_Mobile/components/MobileArchivedFeatureCollections";
import MobileArchivedFeatureProduct from "_Mobile/components/MobileArchivedFeatureProduct";
import MobileLogisticsLanding from "_Mobile/scenes/MobileLogisticsLanding";
import LogisticsLanding from "scenes/LogisticsLanding";
import IndianTripPopup from "components/IndianTripPopup";
import { Provider } from "react-redux";
import { rtkStore } from "./store";

function launchIntoFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}

class App extends Component {
  componentDidMount() {
    window.imageMapResize();
    // launchIntoFullscreen(window.document.documentElement);
    // window.document.body.requestFullscreen(); // fullscreen error
  }

  componentWillMount() {
    const getCollectionsWithMetaFields = async () => {
      try {
        console.log('client.graphqlClient', client, client.graphQLClient.collection)
        const collectionsQuery = client.graphQLClient.query((root) => {
            root.addConnection('collections', {args: {first: 100}}, (collection) => {
              console.log('QL pcollection', collection);
                collection.addConnection('products', {
                    args: { first: 250 }
                }, (Product) => {
                    Product.add('title');
                    Product.add('tags');// Add fields to be returned
                    Product.addConnection('variants', { args: { first: 250 } }, (variants) => {
                      variants.add('product');
                      variants.add('title');
                      // variants.add('price');
                      variants.add('sku');
                    });
                });
              
                collection.add('metafields', {
                  args: {
                    identifiers: [
                      {
                        key: "archive_banner_url",
                        namespace: "custom"
                      },
                      {
                        key: "collection_banner_url_desktop",
                        namespace: "custom"
                      },
                      {
                        key: "collection_banner_url_mobile",
                        namespace: "custom"
                      },
                      {
                        key: "collection_banner_link_path",
                        namespace: "custom"
                      },
                    ]
                  }
                }, (metafield) => {
                  console.log('metafield', metafield);
                  metafield.add('key');
                  metafield.add('namespace');
                  metafield.add('value');
                });
            });
        });
        console.log('client', client );
        const collectionsQuery2 = { query: "query { collections(first: 100) { pageInfo { hasNextPage, hasPreviousPage }, edges { cursor, node { id, metafields(identifiers: [{ key: \"archive_banner_desktop\", namespace: \"custom\" }]) { id, key, namespace, value, reference { ... on MediaImage { image { originalSrc } } } } } } } }" };
        console.log('collectionsQuery', collectionsQuery, 'collectionsQuery2', collectionsQuery2);
        const { model: collectionsModel, data: collectionsData } = await client.graphQLClient.send(collectionsQuery);
        console.log('collectionsModel', collectionsModel, 'collectionsData', collectionsData);
        // const collections = await client.refetch(collectionsModel.collection.metafields.image);
        // console.log('collections', collections);
        return collectionsData?.collections.edges.map(edge => edge.node) || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    };
    // {"query":"query { collections (first: 100) { pageInfo { hasNextPage,hasPreviousPage },edges { cursor,node { id,metafields (identifiers: [{key: \"archive_banner_desktop\" namespace: \"custom\"}]) { id,key,namespace,value } } } } }"}
    const fetchCollectionsWithProducts = async () => {
      try {

        // const oop = await client.graphQLClient.query({
        //   {"query":"query { collections (first: 100) { pageInfo { hasNextPage,hasPreviousPage },edges { cursor,node { id,metafields (identifiers: [{key: \"archive_banner_desktop\" namespace: \"custom\"}]) { id,key,namespace,value } } } } }"}
        // //   query: `query {
        //     metafield(namespace: "custom", key: "archive_banner_desktop") {
        //       references(first: 10) {
        //         nodes {
        //           __typename
        //           ... on MediaImage {
        //             image {
        //               originalSrc
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }`
        // });
        // console.log('oop', oop)

        const collectionsWithProducts = await client.collection.fetchAllWithProducts({ productsFirst: 100 });
        const collectionsWithMetaFields = await getCollectionsWithMetaFields();
  
        // Merge the two collections data sets
        const collections = collectionsWithProducts.map(collection => {
          const matchingCollection = collectionsWithMetaFields.find(c => c.id === collection.id);
          return {
            ...collection,
            metafields: matchingCollection?.metafields
          };
        });
  
        // Do something with the merged collections
        console.log('Merged Collections:', collections);
        // this.props.fetchProducts(collections[0].products);
        // find feaston-booster-pack
        const boosterPack = collections.find((collection) => {
          return collection.handle === "feaston-booster-pack";
        });
        if (boosterPack) {
          this.props.fetchBoosterPack(boosterPack.products || []);
        }

        const OthersActive = collections.find((collection) => {
          const titleParams = collection.title.split(",");
          return titleParams[0].includes("Others") && titleParams.includes("active");
        });
        if (OthersActive) {
          this.props.fetchProducts(OthersActive.products || []);
        }

        const FeatureCollection = collections.find((collection) => {
          const titleParams = collection.title.split(",");
          // return titleParams[0].includes("FeatureItems3");
          return titleParams[0].includes("FeatureItems") && titleParams.includes("active");
        });
        if (FeatureCollection) {
          this.props.fetchFeatureCollection(FeatureCollection || {});
          this.props.fetchProducts(FeatureCollection.products || []);
        } else {
          this.props.fetchFeatureCollection({});
        }

        const ArchivedFeatureCollections = collections.filter((collection) => {
          const titleParams = collection.title.split(",");
          return titleParams[0].includes("FeatureItems") && titleParams.includes("archived");
        });
        if (ArchivedFeatureCollections) {
          this.props.fetchArchivedFeatureCollections(ArchivedFeatureCollections || []);
        }

      } catch (error) {
        console.error(error);
      }
    };
  
    fetchCollectionsWithProducts();

    // client.product.fetchAll(100).then((res) => {
    //   // fetchAll default fetch 20 items
    //   this.props.fetchProducts(res);
    // });
    let { cookies } = this.props;
    const checkoutId = cookies.get("checkoutID");
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((checkout) => {
        if (checkout) {
          if (checkout.completedAt) {
            client.checkout.create().then((res) => {
              cookies.set("checkoutID", res.id, {
                path: "/",
                expires: new Date(Date.now() + 31556952000),
              });
              this.props.fetchCheckout(res);
            });
          } else {
            this.props.fetchCheckout(checkout);
          }
        } else {
          // for creating new checkout if old checkout id is invalid,
          client.checkout.create().then((res) => {
            cookies.set("checkoutID", res.id, {
              path: "/",
              expires: new Date(Date.now() + 31556952000),
            });
            this.props.fetchCheckout(res);
          });
        }
      });
    } else {
      client.checkout.create().then((res) => {
        cookies.set("checkoutID", res.id, {
          path: "/",
          expires: new Date(Date.now() + 31556952000),
        });
        this.props.fetchCheckout(res);
      });
    }
  }

  fullScreenStart(e) {
    // e.preventDefault();
    launchIntoFullscreen(window.document.documentElement);
  }

  render() {
    const { fetchProductsReducer = {} } = this.props;
    const { products = [] } = fetchProductsReducer;
    const projects = Object.keys(categories).map(function (key) {
      return { url: key, ...categories[key] };
    });
    return (
      <div
        className={`App${
          isMobile ? " App_mobile" : " App_desktop"
        }`} /*onMouseDown={this.fullScreenStart}*/
      >
        <style jsx>{`
          .App {
            height: 100vh;
          }
          .App.App_mobile {
            min-width: 100%;
          }
          .App.App_desktop {
            overflow-y: scroll;
          }
          .App > div {
            {/* height: calc(100vh + 40px); */}
            // max-height: 100vh;
            overflow-y: scroll;
            background: transparent;
            -webkit-overflow-scrolling: touch;
          }
          .App.App_mobile > div {
            margin-bottom: 40px;
          }
          div {
            /* for iOS */
            // overflow-y: scroll; /* has to be scroll, not auto */
            // -webkit-overflow-scrolling: touch;
          }
          body {
            overflow: hidden;
            overflow-y: scroll;
          }
        `}</style>
        <UseScrollDirection />
        {/* <BrowserView> */}
        {!isMobile ? (
          <>
            <Navigation />
            <Routes>
              <Route
                exact
                path="/"
                // element={<Home2022June /> /*Home*/}
                // element={<Home2021winter />}
                element={<GreatReset />}
              />
              <Route
                exact
                path="/archives"
                // element={<Home2022June /> /*Home*/}
                element={<ArchivedFeatureCollections />}
                // element={<GreatReset />}
              />
              <Route
                path="/archives/:collection"
                element={<ArchivedFeatureProduct />}
              />
              <Route
                path="/archives/:collection/:product"
                element={<ProjectGeneral />}
              />
              <Route
                exact
                path="/tcg"
                element={<TcgIntro />}
              />
              <Route
                path="/shop/:category"
                element={<Category />}
              />
              <Route path="/about" element={<About />} />
              <Route path="/events" element={<About />} />
              <Route path="/cart" element={<Cart />} />

              <Route path="/stockist" element={<Stockist />} />
              {/* <Route exact path="/project/" element={<Project} /> */}
              {projects.map((project) => {
                return (
                  <Route
                    exact
                    key={project.url}
                    path={`/${project.url}`}
                    element={<project.component />}
                  />
                );
              })}
              {projects.map((project) => {
                return (
                  <Route
                    key={`product_${project.url}`}
                    path={`/${project.url}/:product_handle`}
                    element={<Product />}
                  />
                );
              })}
              {products.map((p) => {
                return (
                  <Route
                    key={`/${encodeURIComponent(p.handle)}`}
                    path={`/${encodeURIComponent(p.handle)}`}
                    element={<ProjectGeneral />}
                  />
                );
              })}
              {/* <Route path={`/project/:project/:product_handle`} element={<Product} /> */}
              <Route path="/terms" element={<Terms />} />
              <Route
                path="/create_receipt"
                element={<CreateReceipt />}
              />
              <Route path="/test" element={<Test />} />
              <Route path="/test2" element={<Test2 />} />
              {/* <Route path="/yearbook" element={<YearBook/>} /> */}
              <Route path="/lookbook" element={<YearBookPage />} />
              <Route
                path="/contact"
                element={<Contact />}
              />
              <Route
                path="/address"
                element={<Address />}
              />
              <Route path="/events/upcoming" element={<Upcoming />} />
              <Route path="/events/past" element={<Past />} />
              <Route path="/feaston_booster_pack" element={<IframeFeastonBoosterPack />} />
              {/* <Route path="/printLabel" element={
                <Provider store={rtkStore}>
                  <PrintLabel />
                </Provider>}
              />
              <Route path="/pickupBox" element={
                <Provider store={rtkStore}>
                  <PickupBox />
                </Provider>}
              /> */}
              <Route path="/logistics" element={<LogisticsLanding />} />
            </Routes>
          </>
        ) : (
          <>
            <MobileNavigation />
            <Routes>
              <Route
                exact
                path="/"
                // element={<MobileCategory />}
                // element={<MobileHome2022June /> /*MobileHome*/}
                // element={<MobileHome2021winter />}
                element={<MobileGreatReset />}
              />
              <Route
                exact
                path="/archives"
                // element={<Home2022June /> /*Home*/}
                element={<MobileArchivedFeatureCollections />}
                // element={<GreatReset />}
              />
              <Route
                path="/archives/:collection"
                element={<MobileArchivedFeatureProduct />}
              />
              <Route
                path="/archives/:collection/:product"
                element={<MobileProjectGeneral />}
              />
              <Route
                exact
                path="/tcg"
                element={<TcgIntro />}
              />
              <Route
                path="/shop/:category"
                element={<MobileCategory />}
              />
              <Route path="/about" element={<About />} />
              <Route path="/events" element={<MobileAbout />} />
              <Route path="/stockist" element={<MobileStockist />} />
              {/* <Route path="/project/" element={<MobileProject/>} /> */}
              {projects.map((project) => {
                return (
                  <Route
                    exact
                    key={`mobile_${project.url}`}
                    path={`/${project.url}`}
                    element={<project.mobileComponent />}
                  />
                );
              })}
              {projects.map((project) => {
                return (
                  <Route
                    key={`mobile_product_${project.url}`}
                    path={`/${project.url}/:product_handle`}
                    element={<MobileProduct />}
                  />
                );
              })}
              {products.map((p) => {
                return (
                  <Route
                    key={`/${encodeURIComponent(p.handle)}`}
                    path={`/${encodeURIComponent(p.handle)}`}
                    element={<MobileProjectGeneral />}
                  />
                );
              })}
              {/* <Route path={`/project/:project/:product_handle`} element={<MobileProduct/>} /> */}
              <Route path="/terms" element={<MobileTerms />} />
              <Route path="/cart" element={<MobileCart />} />
              <Route
                path="/create_receipt"
                element={<CreateReceipt />}
              />
              {/* <Route path="/yearbook" element={<MobileYearBook/>} /> */}
              <Route
                path="/lookbook"
                element={<MobileYearBookPage />}
              />
              <Route
                path="/contact"
                element={<MobileContact />}
              />
              <Route
                path="/address"
                element={<MobileAddress />}
              />
              <Route path="/events/upcoming" element={<MobileUpcoming />} />
              <Route path="/events/past" element={<MobilePast />} />
              <Route path="/feaston_booster_pack" element={<IframeFeastonBoosterPack />} />
              {/* <Route path="/printLabel" element={
                <Provider store={rtkStore}>
                  <PrintLabel />
                </Provider>}
              />
              <Route path="/pickupBox" element={
                <Provider store={rtkStore}>
                  <PickupBox />
                </Provider>}
              /> */}
              <Route path="/logistics" element={<MobileLogisticsLanding />} />
            </Routes>
          </>
        )}
        <IndianTripPopup />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: (products) => dispatch(fetchProducts(products)),
  fetchCheckout: (checkout) => dispatch(fetchCheckout(checkout)),
  fetchBoosterPack: (products) => dispatch(fetchBoosterPack(products)),
  fetchFeatureCollection: (collection) => dispatch(fetchFeatureCollection(collection)),
  fetchArchivedFeatureCollections: (collections) => dispatch(fetchArchivedFeatureCollections(collections)),
});

export default withRouter(
  withCookies(connect(mapStateToProps, mapDispatchToProps)(App))
);
