import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bannerImage from 'assets/Logistics/logistics_banner1.png';
import YELLOW_LOGO from '../assets/yellow_logo.svg';
import './style.scss';

const BannerSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className='BannerSlider'>
      {/* <Slider {...settings}> */}
        <div>
          <div className="slide">
            <img src={bannerImage} alt="Warehouse storage" className="banner-bg" />
            <div className="banner-content">
              <div className="banner-content-inner">
                <div className='yellow-logo'>
                  <img src={YELLOW_LOGO} alt="" />
                </div>
                <div className="top-title">Door to Door</div>
                <div className="main-title">Storage</div>
                <p>
                Squeeze out those valuable square feets by storing your belongings with us Feaston Industrial Centre where an wall of opium carton stands. Our in-house logistics team will transport packed cartons back to our site at Kowloon Bay and look after them personally. Need to recall something?
                <br/>Book an appointment to have it at your doorstep
                </p>
                <a aria-label="Chat on WhatsApp" href="https://wa.me/60283591" target="_blank" rel="noopener noreferrer">
                  <button>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <div className="slide">
            <img src={bannerImage} alt="Warehouse storage" className="banner-bg" />
            <div className="banner-content">
              <div className="banner-content-inner">
                <div className='yellow-logo'>
                  <img src={YELLOW_LOGO} alt="" />
                </div>
                <div className="top-title">Door to Door</div>
                <div className="main-title">Storage</div>
                <p>
                Squeeze out those valuable square feets by storing your belongings with us Feaston Industrial Centre where an wall of opium carton stands. Our in-house logistics team will transport packed cartons back to our site at Kowloon Bay and look after them personally. Need to recall something?
                <br/>Book an appointment to have it at your doorstep
                </p>
                <a aria-label="Chat on WhatsApp" href="https://wa.me/60283591">
                  <button>BOOK NOW</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Slider> */}
    </div>
  );
};

export default BannerSlider;