import React from 'react';
import BOX from 'assets/Logistics/logistics_box.png';

const BoxDetail = ({ box }) => {
  const { size, price, freq, detail1, detail2} = box;
  return (
    <div className="BoxDetail">
      <div className="image">
        <img className="box" src={BOX} alt="" />
      </div>
      <div className="detail">
          <div className="size">{size}</div>
          <div className="price">{price}</div>
          <div className="freq">{freq}</div>
          <div className="detail1">{detail1}</div>
          {detail2 && <div className="detail2">{detail2}</div>}
      </div>
    </div>
  );
};

export default BoxDetail;
