import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import airtableApi from './data-manager/airTableApi';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export function oldConfigureStore(initialState={}) {
 return createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
 );
}

//  RTK:
export const rtkStore = configureStore({
  reducer: {
    ...rootReducer,
    [airtableApi.reducerPath]: airtableApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(airtableApi.middleware),
});

