const boxLists = [
  {
    size: "Standard",
    price: "$60",
    freq: "box/month",
    detail1: "56(L) 38(W) 46(H)",
    detail2: "<15kg"
  },
  {
    size: "Large",
    price: "$70",
    freq: "box/month",
    detail1: "63(L) 38(W) 48(H)",
    detail2: "<20kg"
  },
  {
    size: "Irregular",
    price: "$80",
    freq: "item/month",
    detail1: "(L+W+H) < 230",
  },
  {
    size: "Huge",
    price: "Quote",
    freq: "item/month",
    detail1: "(L+W+H) > 230",
  }
];

export default boxLists;